.mains {
  width: 100%;
  height: 100%;
}

.navigation {
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-brand,
.navbar-nav .nav-link {
  color: black;
  font-size: 20px;
  padding-left: 0px;


}

.navbar-brand:hover,
.navbar-nav .nav-link:hover {
  color: red;

}

.appoint {
  color: black;
  border-radius: 16px;
  margin-left: 0px;
  background-color: lightgray;
  text-align: center;
}

.appoint2 {
  width: 50px;
  background-color: black;
  height: 50px;
  border-radius: 50%;
  margin-left: 15px;
  color: white;
}

.appoint2 a i {
  color: white;
  margin-left: 13px;
  font-size: 26px;
  font-weight: bold;
  margin-top: 12px;
}

.head {
  width: 100%;
  background-color: #f9fad8;
  height: 40px;
  display: flex;
}

.head2 {
  width: 32%;
  height: 100%;
  margin-left: 15px;
}



.head2 p {
  color: black;
  margin-left: 10px;
  padding-top: 04px;
  font-size: 15px;
  font-weight: bold;
}


.header {
  height: 450px;
  width: 100%;
  display: flex;
}


.header2 {
  width: 100%;
  height: 100%;
  margin-left: 0px;
  margin-top: 0px;
  box-shadow: 2px 3px 5px -2px gray;
}

.carousel-image {
  height: 500px;
  width: 100%;
}



p.test2 {
  writing-mode: vertical-rl;
  font-weight: bold;
  margin-top: 40px;
  margin-left: 50px;
  font-size: 25px;
}

.abouts {
  width: 100%;
  height: 450px;
  margin-top: 100px;
  display: flex;
  border: 1px solid lightgray;
}

.abouts2 {
  width: 50%;
  height: 100%;
}

.abouts2 h1 {
  margin-left: 40px;
  margin-top: 20px;
  color: black;
}

.abouts2 p {
  margin-left: 40px;
  color: black;
  margin-top: 20px;
}

.abouts3 {
  width: 50%;
  height: 100%;
  display: flex;
}


.aboutpic {
  width: 30%;
  height: 320px;
  margin-left: 15px;
  margin-top: 50px;
}

.abouts3  img {
  width: auto;
  height: 100%;
  margin-left: 80px;
}

.depart {
  width: 100%;
  height: 100%;
  margin-top: 100px;
}


.depart3 {
  width: 100%;
  height: 330px;
  display: flex;
  margin-top: 15px;
}

.face {
  width: 27%;
  height: 100%;
  margin-left: 55px;
  background-color: lightgray;
  border-radius: 16px;
  text-decoration: none;

}



.face h1 {
  font-size: 23px;
  text-align: center;
  padding-top: 35px;
  color: black;
}

.face h1:hover {
  color: red;
  font-size: 25px;
}


.face img {
  margin-left: 30px;
  width: 300px;
  height: 200px;
  margin-top: 20px;
  transition: transform 0.3s ease-out;
  object-fit: cover;
}

.face:hover img {
  transform: translateY(-20px);
  /* Adjust the distance you want the image to move */
}




.ankus {
  width: 100%;
  height: 450px;
  margin-top: 15px;
  border: 1px solid gray;
  display: flex;
}

.ankus2 {
  width: 50%;
  height: 100%;
  margin-left: 0px;
}

.ankus2 h1 {
  font-size: 3rem;
  margin-left: 10px;
  color: red;
  margin-top: 20px;
}

.ankus2 p {
  font-size: 18px;
  margin-left: 10px;
  color: black;
  font-family: 'Times New Roman', Times, serif;
  margin-top: 50px;
}

.ankus2 button {
  margin-left: 100px;
  width: 120px;
  font-weight: bold;
  margin-top: 50px;
  border-radius: 16px;
  background-color: black;
  color: white;
}

.ankus2 img {
  width: 100%;
  height: 100%;
}




.footer {
  width: 100%;
  height: 450px;
  border: 1px solid lightgray;
  display: flex;
  margin-top: 100px;

}

.foot {
  width: 25%;
  height: 100%;
}

.foot h1 {
  text-align: center;
  font-size: 25px;
  margin-top: 60px;

}

.foot p {
  margin-left: 15px;
  margin-top: 30px;
}

.foot a i {
  width: 60px;
  height: 60px;
  position: sticky;
  bottom: 0;
  z-index: 999;
  border-radius: 50%;
  padding-left: 15px;
  font-size: 35px;
  padding-top: 12px;
  color: white;
  background-color: black;
}


.link {
  margin-left: 90px;
  font-size: 20px;
  text-decoration: none;
  color: black;
  margin-top: 20px;
}

.contact {
  width: 95%;
  height: 500px;
  margin-top: 100px;
  display: flex;
}

.contact2 {
  width: 48%;
  height: 100%;
  margin-left: 20px;

}


.appointment {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
}

h2 {
  text-align: center;
  color: #333;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 8px;
}

input {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
}

button {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}


.emergency {
  width: 100%;
  height: 400px;
  margin-top: 30px;
}

.emergency h1 {
  text-align: center;
}

.emergency p {
  margin-left: 100px;
  margin-top: 10px;
  font-size: 18px;
}

.emergency img {
  margin-left: 300px;
}


.gyne {
  height: 450px;
  display: flex;
  width: 100%;
  margin-top: 50px;
}

.gyne2 {
  height: 450px;
  width: 50%;
}

.gyne2 p {
  padding-left: 20px;
  padding-top: 10px;
}

.gyne2 img {
  width: 100%;
}


.doctor {
  width: 100%;
  height: 200px;
  display: flex;
  margin-top: 50px;

}

.doctor2 {
  width: 24%;
  height: 100%;

  margin-left: 160px;
  background-color: #f9fad8;
  border-radius: 10px;
}

.doctor2 h1 {
  text-align: center;
  font-size: 30px;
  margin-top: 30px;
}

.doctor2 p {
  font-weight: bold;
  padding-top: 20px;
  padding-left: 30px;
  font-size: 17px;
}





@media (min-width: 300px) and (max-width: 600px) {

  .mains {
    width: 100%;
    height: auto;
  }

  .navigation {
    position: sticky;
    top: 0;
    z-index: 999;
  }

  .navbar-brand,
  .navbar-nav .nav-link {
    color: black;
    font-size: 20px;
    padding-left: 0px;

  }

  .navbar-brand:hover,
  .navbar-nav .nav-link:hover {
    color: red;

  }

  .appoint {
    color: white;
    border-radius: 16px;
    margin-left: 0px;
    background-color: blue;
    text-align: center;

  }


  .appoint2 {
    width: 50px;
    background-color: black;
    height: 50px;
    border-radius: 50%;
    margin-left: 15px;
    color: white;
    display: none;
  }

  .appoint2 a i {
    color: white;
    margin-left: 13px;
    font-size: 26px;
    font-weight: bold;
    margin-top: 12px;
  }

  .head {
    width: 100%;
    background-color: #f9fad8;
    height: 100%;
    display: flex;
    flex-direction: column;

  }

  .head2 {
    width: 100%;
    height: 25px;
    margin-left: 0px;

  }

  .head2 p {
    color: black;
    margin-left: 10px;
    padding-top: 04px;
    font-size: 12px;
  }


  .header {
    height: 100%;
    width: 100%;
    display: flex;

  }


  .header2 {
    width: 100%;
    height: 100%;
    margin-left: 0px;
    margin-top: 0px;
    border: 1px solid gray;
    box-shadow: 2px 3px 5px -2px gray;
  }

  .carousel-image {
    height: 220px;
    width: 100%;

  }



  p.test2 {
    writing-mode: vertical-rl;
    font-weight: bold;
    margin-top: 40px;
    margin-left: 80px;
    font-size: 25px;
  }


  .abouts {
    width: 100%;
    height: 100%;
    flex-direction: column;
    margin-top: 20px;
    display: flex;

  }

  .abouts2 {
    width: 100%;
    height: 100%;

  }

  .abouts2 h1 {
    margin-left: 10px;
    margin-top: 10px;
    color: black;
  }

  .abouts2 p {
    margin-left: 10px;
    color: black;
    margin-top: 20px;
  }

  .abouts3 {
    width: 100%;
    height: 100%;
    display: flex;
  }


  .aboutpic {
    width: 30%;
    height: 250px;

    margin-left: 10px;
    margin-top: 50px;
  }

  .abouts3  img {
    width: 100%;
    height: 100%;
    margin-left: 0px;
  }

  .depart {
    width: 100%;
    height: 100%;
    margin-top: 100px;
  }


  .depart3 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }

  .face {
    width: 100%;
    height: 320px;
    margin-left: 0px;
    background-color: lightgray;
    border-radius: 16px;
    text-decoration: none;
    margin-top: 15px;

  }



  .face h1 {
    font-size: 23px;
    text-align: center;
    padding-top: 35px;
    color: black;
  }

  .face h1:hover {
    color: red;
    font-size: 25px;
  }


  .face img {
    margin-left: 30px;
    width: 300px;
    height: 200px;
    margin-top: 20px;
    transition: transform 0.3s ease-out;
    object-fit: cover;
  }

  .face:hover img {
    transform: translateY(-20px);
    /* Adjust the distance you want the image to move */
  }



  .ankus {
    width: 100%;
    height: 100%;
    margin-top: 15px;
    border: 1px solid gray;
    display: flex;
    flex-direction: column;
  }

  .ankus2 {
    width: 100%;
    height: 100%;
    margin-left: 0px;
  }

  .ankus2 h1 {
    font-size: 1.5rem;
    margin-left: 10px;
    color: red;
    margin-top: 20px;
  }

  .ankus2 p {
    font-size: 18px;
    margin-left: 10px;
    color: black;
    font-family: 'Times New Roman', Times, serif;
    margin-top: 50px;
  }

  .ankus2 button {
    margin-left: 40px;
    width: 120px;
    font-weight: bold;
    margin-top: 50px;
    border-radius: 16px;
    background-color: black;
    color: white;
  }

  .ankus2 img {
    width: 100%;
    height: 250px;
    margin-top: 15px;
  }


  .footer {
    width: 100%;
    height: 100%;
    flex-direction: column;
    display: flex;
    margin-top: 100px;
    border: 1px solid lightgray;
  }

  .foot {
    width: 100%;
    height: 100%;
  }

  .foot h1 {
    text-align: center;
    font-size: 25px;
    margin-top: 30px;

  }

  .foot p {
    margin-left: 15px;
    margin-top: 30px;
  }

  .link {
    margin-left: 90px;
    font-size: 20px;
    text-decoration: none;
    color: black;
    margin-top: 20px;
  }


  .contact {
    width: 100%;
    height: 100%;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
  }

  .contact2 {
    width: 100%;
    height: 100%;
    margin-left: 0px;

  }

  .emergency {
    width: 100%;
    height: 400px;
    margin-top: 30px;
  }

  .emergency h1 {
    text-align: center;
  }

  .emergency p {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 14px;
  }

  .emergency img {
    margin-left: 0px;
    width: 100%;
    height: 100%;
  }

  .gyne {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 50px;
  }

  .gyne2 {
    height: 450px;
    width: 100%;
  }

  .gyne2 p {
    padding-left: 10px;
    padding-top: 10px;
    font-size: 14px;
  }

  .gyne2 img {
    width: 100%;
  }



  .doctor {
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: 50px;
flex-direction: column;
  }

  .doctor2 {
    width: 95%;
    height: 200px;
margin-top: 10px;
    margin-left: 10px;
    background-color: #f9fad8;
    border-radius: 10px;
  }

  .doctor2 h1 {
    text-align: center;
    font-size: 30px;
    margin-top: 30px;
  }

  .doctor2 p {
    font-weight: bold;
    padding-top: 20px;
    padding-left: 30px;
    font-size: 17px;
  }
}


@media (min-width: 600px) and (max-width: 1200px) {
  .mains {
    width: 1200px;
    height: 100%;
  }

  .navigation {
    position: sticky;
    top: 0;
    z-index: 999;
  }

  .navbar-brand,
  .navbar-nav .nav-link {
    color: black;
    font-size: 20px;
    padding-left: 0px;


  }

  .navbar-brand:hover,
  .navbar-nav .nav-link:hover {
    color: red;

  }

  .appoint {
    color: black;
    border-radius: 16px;
    margin-left: 0px;
    background-color: lightgray;
    text-align: center;
    width: 200px;
  }

  .appoint2 {
    width: 50px;
    background-color: black;
    height: 50px;
    border-radius: 50%;
    margin-left: 15px;
    color: white;
  }

  .appoint2 a i {
    color: white;
    margin-left: 14px;
    font-size: 24px;
    font-weight: bold;
    margin-top: 12px;
  }

  .head {
    width: 100%;
    background-color: #f9fad8;
    height: 40px;
    display: flex;
  }

  .head2 {
    width: 32%;
    height: 100%;
    margin-left: 15px;
  }



  .head2 p {
    color: black;
    margin-left: 0px;
    padding-top: 04px;
    font-size: 13px;
    font-weight: bold;
  }


  .header {
    height: 450px;
    width: 100%;
    display: flex;
  }


  .header2 {
    width: 100%;
    height: 100%;
    margin-left: 0px;
    margin-top: 0px;
    box-shadow: 2px 3px 5px -2px gray;
  }

  .carousel-image {
    height: 500px;
    width: 100%;
  }



  p.test2 {
    writing-mode: vertical-rl;
    font-weight: bold;
    margin-top: 40px;
    margin-left: 50px;
    font-size: 25px;
  }

  .abouts {
    width: 100%;
    height: 450px;
    margin-top: 100px;
    display: flex;
    border: 1px solid lightgray;
  }

  .abouts2 {
    width: 50%;
    height: 100%;
  }

  .abouts2 h1 {
    margin-left: 40px;
    margin-top: 20px;
    color: black;
  }

  .abouts2 p {
    margin-left: 40px;
    color: black;
    margin-top: 20px;
  }

  .abouts3 {
    width: 50%;
    height: 100%;
    display: flex;
  }


  .aboutpic {
    width: 30%;
    height: 320px;
    margin-left: 15px;
    margin-top: 50px;
  }

  .abouts3  img {
    width: auto;
    height: 100%;
    margin-left: 80px;
  }

  .depart {
    width: 100%;
    height: 100%;
    margin-top: 100px;
  }


  .depart3 {
    width: 100%;
    height: 330px;
    display: flex;
    margin-top: 15px;
  }

  .face {
    width: 27%;
    height: 100%;
    margin-left: 55px;
    background-color: lightgray;
    border-radius: 16px;
    text-decoration: none;

  }



  .face h1 {
    font-size: 23px;
    text-align: center;
    padding-top: 35px;
    color: black;
  }

  .face h1:hover {
    color: red;
    font-size: 25px;
  }


  .face img {
    margin-left: 30px;
    width: 300px;
    height: 200px;
    margin-top: 20px;
    transition: transform 0.3s ease-out;
    object-fit: cover;
  }

  .face:hover img {
    transform: translateY(-20px);
    /* Adjust the distance you want the image to move */
  }




  .ankus {
    width: 100%;
    height: 450px;
    margin-top: 15px;
    border: 1px solid gray;
    display: flex;
  }

  .ankus2 {
    width: 50%;
    height: 100%;
    margin-left: 0px;
  }

  .ankus2 h1 {
    font-size: 3rem;
    margin-left: 10px;
    color: red;
    margin-top: 20px;
  }

  .ankus2 p {
    font-size: 18px;
    margin-left: 10px;
    color: black;
    font-family: 'Times New Roman', Times, serif;
    margin-top: 50px;
  }

  .ankus2 button {
    margin-left: 100px;
    width: 120px;
    font-weight: bold;
    margin-top: 50px;
    border-radius: 16px;
    background-color: black;
    color: white;
  }

  .ankus2 img {
    width: 100%;
    height: 100%;
  }




  .footer {
    width: 100%;
    height: 450px;
    border: 1px solid lightgray;
    display: flex;
    margin-top: 100px;

  }

  .foot {
    width: 25%;
    height: 100%;
  }

  .foot h1 {
    text-align: center;
    font-size: 25px;
    margin-top: 60px;

  }

  .foot p {
    margin-left: 15px;
    margin-top: 30px;
  }

  .foot a i {
    width: 60px;
    height: 60px;
    position: sticky;
    bottom: 0;
    z-index: 999;
    border-radius: 50%;
    padding-left: 15px;
    font-size: 35px;
    padding-top: 12px;
    color: white;
    background-color: black;
  }


  .link {
    margin-left: 90px;
    font-size: 20px;
    text-decoration: none;
    color: black;
    margin-top: 20px;
  }

  .contact {
    width: 95%;
    height: 500px;
    margin-top: 100px;
    display: flex;
  }

  .contact2 {
    width: 48%;
    height: 100%;
    margin-left: 20px;

  }


  .appointment {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
  }

  h2 {
    text-align: center;
    color: #333;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  label {
    margin-bottom: 8px;
  }

  input {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    box-sizing: border-box;
  }

  button {
    background-color: #4caf50;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  button:hover {
    background-color: #45a049;
  }


  .emergency {
    width: 100%;
    height: 400px;
    margin-top: 30px;
  }

  .emergency h1 {
    text-align: center;
  }

  .emergency p {
    margin-left: 100px;
    margin-top: 10px;
    font-size: 18px;
  }

  .emergency img {
    margin-left: 300px;
  }


  .gyne {
    height: 450px;
    display: flex;
    width: 100%;
    margin-top: 50px;
  }

  .gyne2 {
    height: 450px;
    width: 50%;
  }

  .gyne2 p {
    padding-left: 20px;
    padding-top: 10px;
  }

  .gyne2 img {
    width: 100%;
  }

  
.doctor{
  width: 100%;
  height: 200px;
  display: flex;
  margin-top: 50px;
  
}

.doctor2{
  width: 24%;
  height: 100%;
 
  margin-left: 10px;
  background-color: #f9fad8;
  border-radius: 10px;
}

.doctor2 h1 {
  text-align: center;
  font-size: 30px;
  margin-top: 30px;
}

.doctor2 p {
font-weight: bold;
  padding-top: 20px;
  padding-left: 30px;
  font-size: 17px;
}
}

/* Gallery.css */

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.gallery2 {
  flex: 0 0 48%;
  /* Adjust the width as needed */
  margin-bottom: 10px;
}

.gallery2 img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  /* Optional: Add border-radius for rounded corners */
}

/* Add any additional styling as needed */